<template>
  <div class="noauth">
    <div class="noauth-color py-4">
      <main
        class="flex flex-col md:flex-row lg:gap-x-5 xl:gap-x-20 h-screen w-full items-center xl:justify-evenly justify-center"
      >
        <section class="mb-4 md:mb-0">
          <img
            src="~/assets/images/uma-logo.svg"
            class="xl:flex-1 h-[65px] md:h-28 no-auth__logo-width hidden sm:block"
            :class="{
              'blur-content': accountAlreadyExists || registrationNotFound,
            }"
          >
        </section>
        <section class="flex items-center">
          <slot />
        </section>
        <section class="mt-4 md:mt-0">
          <img
            src="~/assets/images/sm-logo-white.svg"
            class="xl:flex-1 h-[65px] md:h-[80px] xl:h-28 no-auth__logo-width hidden sm:block"
            :class="{
              'blur-content': accountAlreadyExists || registrationNotFound,
            }"
          >
          <img
            src="~/assets/images/plus_sign_large.svg"
            class="xl:flex-1 mt-4 m-auto hidden xl:block"
            :class="{
              'blur-content': accountAlreadyExists || registrationNotFound,
            }"
            alt="plus_sign_large"
          >
          <img
            src="~/assets/images/SmartConnectTM_White.svg"
            class="xl:flex-1 h-28 no-auth__logo-width hidden xl:block"
            :class="{
              'blur-content': accountAlreadyExists || registrationNotFound,
            }"
            alt="smart_connect_white"
          >
        </section>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store/user";
const userStore = useUserStore();
const { registrationNotFound, accountAlreadyExists } = storeToRefs(userStore);
</script>

<style>
.blur-content {
	filter: brightness(0.47);
}

.noauth {
	background-image: url("~/assets/images/noauth-bg.svg");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh !important;
}
.noauth-color {
	@apply bg-cover bg-no-repeat min-h-screen;
	@apply bg-tablet-mobile-desktop sm:bg-tablet-vertical md:bg-tablet-mobile-desktop;
}
</style>
